@import url("https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,700i");

/* * {
  font-family: "DM Sans", sans-serif !important;
} */

.new-dw * {
  font-family: "DM Sans", sans-serif !important;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  -webkit-overflow-scrolling: touch;
}

/*  */
.cc-window .cc-btn:last-child {
  min-width: 21px !important;
  margin-right: 0.8rem;
  padding: 0 !important;
  border: none;
  position: relative !important;
}

.cc-banner .cc-message {
  text-align: center;
  font-size: 15px;
}

.cc-window .cc-compliance .cc-btn:last-child:before {
  content: "" !important;
  width: 100% !important;
  height: 1px !important;
  position: absolute !important;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff !important;
}

.cc-window .cc-compliance .cc-btn:last-child:after {
  transform: rotate(-45deg);
}

.cc-window .cc-btn:last-child:after {
  content: "" !important;
  width: 100% !important;
  height: 1px !important;
  position: absolute !important;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff !important;
}

.cc-color-override--113228377.cc-window {
  position: relative;
  height: 27px;
  padding: 0px;
}

.cc-window .cc-compliance .cc-btn:last-child:before {
  transform: rotate(45deg);
}
.cc-window .cc-btn:last-child:before {
  content: "" !important;
  width: 100% !important;
  height: 1px !important;
  position: absolute !important;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff !important;
}

.cc-window .cc-compliance .cc-btn:last-child:after {
  transform: rotate(-45deg);
}

.cc-window .cc-btn:last-child:after {
  content: "" !important;
  width: 100% !important;
  height: 1px !important;
  position: absolute !important;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff !important;
}

.cc-grower {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
  max-height: 29px;
}
